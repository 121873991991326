ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h6 {
  font-weight: 400;
  font-size: 1em;
}

h5 {
  font-size: 1.4em;
  margin: auto;
}
ul.pagination {
  display: flex;
  list-style-type: none;
}

.item {
  list-style-type: none;
  margin: 10px;
  font-size: 1.3em;
}

.linkClass {
  list-style-type: none;
  color:grey;
}

.activeLinkClass {
  color: #FF4500;
}


