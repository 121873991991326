ul.pagination {
  display: flex;
  list-style-type: none;
}

.item {
  list-style-type: none;
  margin: 10px;
  font-size: 1.3em;
}

.linkClass {
  list-style-type: none;
  color:grey;
}

.activeLinkClass {
  color: #FF4500;
}

