ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h6 {
  font-weight: 400;
  font-size: 1em;
}

h5 {
  font-size: 1.4em;
  margin: auto;
}